import React, { Fragment } from 'react'
import { Global } from '@emotion/react'
import { globalStyles } from '../components/layout.styles'
import SEO from "../components/Seo"
import NavBar from '../components/NavBar3'
import Footer from '../components/Footer'
import Slider from '../components/SliderNossosDiferenciais'
import Banner from '../images/new/banners/NOSSOS-DIFERENCIAIS.png'
import Rocket from '../images/new/banners/NOSSOS-DIFERENCIAIS-3.svg'

import { 
  section,
  HeaderBanner,
  container,
  content,
  bottomImg2
} from '../components/styles/Base.styles'

const NossosDiferenciais = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Nossos Diferenciais | Um reino de aprendizado e conhecimento" />
    <NavBar />
    <div css={HeaderBanner}>
      <img src={Banner} alt='' style={{ width: '100%' }} />
    </div>
    <div css={[section, content]} style={{ paddingBottom: 0 }}>
      <div css={container}>
        <h1>Nossos Diferenciais</h1>
        <p>Construirmos uma nova forma de ensinar! Queremos formar mais do que alunos, mas também cidadãos preparados para os desafios do futuro, democratizando o ensino privado e de qualidade para todos.</p>
        <p>Um ambiente onde o seu filho(a) goste de estudar e que tenha genuíno interesse em aprender, falando inglês, com metodologias diferentes e aprendizagem adaptativa.</p>
        <h2>Filosofia Kingdom</h2>
        <p>Acreditamos que somos diferentes e que para brilharmos, precisamos entender os nossos potenciais, receber recursos para acessá-los e cruzar com pessoas significativas e que tenham em nossas vidas um papel agregador, transformador e determinante à nossa formação como pessoa.</p>
        <h2>Personalização</h2>
        <p>Não nos apegamos a números, diagnósticos, notas ou resultados, recebemos, acima de tudo, uma pessoa. É nela que focamos em dar nosso melhor. Não acreditamos que exista alguém melhor que o outro, e sim que somos diferentes e que precisamos entender nosso potencial para alcançar nossos objetivos.</p>
        <div style={{ marginBottom: 72, marginTop: 72 }}>
          <Slider />
        </div>
        <h2>Estrutura</h2>
        <p>Temos um espaço totalmente pensado para o desenvolvimento psicomotor das crianças. Elas terão contato somente com áreas pensadas exclusivamente para favorecer o estímulo das habilidades sociais, psicológicas e motoras.</p>
        <h2>Professores</h2>
        <p>Nossa equipe de professores já está há muito tempo na Kingdom Kids. Todos são treinados e capacitados de acordo com o que acreditamos e pensamos para o melhor desenvolvimento dos alunos.</p>
        <h2>Gestão Estruturada</h2>
        <p>Somos uma escola consolidada. Os idealizadores do projeto Kingdom Kids estão até hoje na gestão e transformam vidas com a mesma essência ao longo dos anos e mantêm-se fiéis àquilo que acreditam.</p>
        <h2>Inglês</h2>
        <p>Temos um programa de fluência em inglês. Acreditamos que a prática diária do idioma facilita o aprendizado da língua estrangeira, possibilitando, assim, uma comunicação fluida.</p>
      </div>
    </div>
    <div css={bottomImg2}>
      <img src={Rocket} alt='' style={{ width: '10%' }} />
    </div>
    <Footer />
  </Fragment>
)

export default NossosDiferenciais
